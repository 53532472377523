import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import Button from '../components/Button';


const Mortgage = () => (
    <Layout>
    <Helmet   
      htmlAttributes={{
      lang: 'en',
    }}>
      <title lang="en">Mortgage Info | On The Block Real Estate International</title>
      <meta name="description" content="On The Block Real Estate International Is Now Offering 
      Mortgage Options. Need Pre-Approval or Refinancing Assistance? Connect With Our Team Today" 
      lang="en"/>
    </Helmet>
      <section className="pt-20 md:pt-40 JoinBack">
        <div className="mx-auto px-4">
            <h1 className="text-6xl font-bold leading-none text-white pb-40 drop-shadow-sm">
               Learn About<br/>
               Mortgage Options
            </h1>
        </div>
      </section>
  
      <section className="pt-5">
        <div className="container mx-auto">
        <h2 className="text-3xl mx-5 font-semibold">Need Pre-Approval or Refinancing Assistance?</h2>
        <div className="container mx-auto my-5 py-5 bg-gray-200 rounded-lg">
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
            Connect with us today to get your refinancing in order and take advantage of low
            interest rates.
            </p>
            <a href="tel:562-972-1390">
            <Button className="mt-8 mb-5 mx-5 text-xl" size="l">
              Call Now
            </Button>
            </a>
        </div>
        </div>
      </section>
    </Layout>
  );

export default Mortgage;